import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import GameBoard from "./GameBoard";
import LandingPage from "../LandingPage";

const Game = () => {
  const [gameStarted, setGameStarted] = useState(true);

  const handleStartGame = () => {
    setGameStarted(true);
  };

  return (
    <View style={styles.container}>
      {!gameStarted ? (
        <LandingPage onStartGame={handleStartGame} />
      ) : (
        <GameBoard setGameStarted={setGameStarted} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Game;
