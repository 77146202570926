import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Linking,
  Platform,
} from "react-native";
import { PADDING_LEFT } from "../types";
import Checkbox from "expo-checkbox";
import Terms from "./Terms";

const RegisterForm = ({ onStartGame }) => {
  const [data, setData] = useState([
    { key: "full_name", name: "Ονοματεπώνυμο", value: "" },
    { key: "phone", name: "Κινητό", value: "" },
    { key: "email", name: "Email", value: "" },
  ]);
  const [error, setError] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const validateFields = () => {
    const invalid = data.slice(0, -1).filter((el) => el.value === "")[0];
    setError("");
    let full_name = (data[0]?.value || "")?.trim();
    let firstName = full_name.split(" ").slice(0, 1).join(" ");
    let lastName = full_name.split(" ").slice(1).join(" ");
    if (invalid) {
      setError(`Παρακαλώ συμπληρώστε το πεδίο: ${invalid.name}`);
      return false;
    }
    if (!firstName || !lastName) {
      setError("Παρακαλώ συμπληρώστε το: Ονοματεπώνυμο");
      return false;
    }
    if (!isChecked) {
      setError("Παρακαλώ αποδεχτείτε τους όρους");
      return false;
    }
    return true;
  };

  const handleStartGame = () => {
    // onStartGame()
    // return
    setError("");
    setIsDisabled(true);
    if (validateFields()) {
      const body = JSON.stringify(
        data.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {})
      );
      fetch("https://fotokiklosi.gr/wp-json/api/v1/event-register", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code === "rest_missing_callback_param") {
            setError("Παρακαλώ συμπληρώστε όλα τα πεδία");
          } else if (res.code === "rest_invalid_param") {
            setError("Παρακαλώ συμπληρώστε ένα έγκυρο email");
          } else {
            onStartGame();
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          setError("Παρακαλώ δοκιμάστε ξανά");
        });
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <>
      <View style={styles.root}>
        {data.map((el, index) => {
          const { key, name, value } = el;
          return (
            <View key={index}>
              <Text>
                {name} {name !== "Email" && "*"}
              </Text>
              <View style={styles.inputContainer}>
                <TextInput
                  value={value}
                  onChangeText={(text) => {
                    const updatedData = [...data];
                    updatedData[index] = { ...el, value: text };
                    setData(updatedData);
                  }}
                  keyboardType={key === "phone" ? "number-pad" : "default"}
                />
              </View>
            </View>
          );
        })}
        <Text style={styles.error}>{error}</Text>
        <View style={[styles.row, styles.mb]}>
          <Checkbox
            style={styles.checkbox}
            value={isChecked}
            onValueChange={setChecked}
          />
          <View style={styles.row}>
            <Text>Αποδέχομαι τους </Text>
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === "web") {
                  Linking.openURL(
                    "https://fotokiklosi.gr/wp-content/uploads/2023/12/COMPETITION-TERMS-2023-AB.pdf"
                  );
                } else {
                  setModalVisible(!modalVisible);
                }
              }}
            >
              <Text style={styles.link}>όρους διαγωνισμού</Text>
            </TouchableOpacity>
          </View>
        </View>
        <TouchableOpacity
          style={styles.button}
          onPress={handleStartGame}
          disabled={isDisabled}
        >
          <Text style={styles.buttonText}>Εγγραφή</Text>
        </TouchableOpacity>
      </View>
      {modalVisible && (
        <Terms modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingLeft: PADDING_LEFT,
  },
  button: {
    backgroundColor: "#21AA50",
    padding: 10,
    borderRadius: 50,
    alignItems: "center",
  },
  buttonText: {
    color: "#FFF",
    fontSize: 18,
    fontWeight: "bold",
  },
  error: {
    color: "red",
  },
  inputContainer: {
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 20,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    width: 500,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  mb: { marginBottom: 10 },
  checkbox: {
    margin: 8,
  },
  link: {
    color: "#21AA50",
  },
});

export default RegisterForm;
