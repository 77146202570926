import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, Text, Platform } from "react-native";
import { Image } from "expo-image";
import RegisterForm from "./components/RegisterForm";
import { ImageBackground } from "react-native";
import { IS_LARGE } from "./types";

const LandingPage = ({ onStartGame }) => {
  const [w, setW] = useState(0);
  const [h, setH] = useState(0);
  useEffect(() => {
    Dimensions.addEventListener("change", ({ screen: { width, height } }) => {
      setH(height);
      setW(width);
      console.log(width, height);
    });
  }, []);
  return (
    <View style={styles.container}>
      <ImageBackground
        source={
          Platform.OS === "web"
            ? require("./assets/memory-game-back-white-1920x1080.jpg")
            : IS_LARGE
            ? require("./assets/sign-memory-game-back-1280x800.jpg")
            : require("./assets/sign-memory-game-back-1024x600.jpg")
        }
        resizeMode="cover"
        style={styles.container}
      >
        {/* <Image
                    style={styles.logo}
                    source={require('./assets/icon.png')}
                /> */}
        <RegisterForm onStartGame={onStartGame} />
        {/* <Text>
                    Πλάτος: {w}
                    {"   "}
                    Ύψος: {h}
                </Text> */}
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width:
      Platform.OS === "web"
        ? Dimensions.get("screen").width
        : IS_LARGE
        ? 1280
        : 1024,
  },
  logo: {
    width: 200,
    height: 200,
  },
});

export default LandingPage;
