import React from "react";
import { Alert, Modal, StyleSheet, Text, Pressable, View } from "react-native";
import { WebView } from "react-native-webview";
import Constants from "expo-constants";
import { AntDesign } from "@expo/vector-icons";
import { TERMS_HTML } from "../types";

const Terms = ({ modalVisible, setModalVisible }) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.modalView}>
        <View style={styles.modalContainer}>
          <WebView style={styles.container} source={{ html: TERMS_HTML }} />
          <Pressable
            style={[styles.buttonClose]}
            onPress={() => setModalVisible(!modalVisible)}
          >
            <AntDesign name="closecircleo" size={30} color="#21AA50" />
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Constants.statusBarHeight,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalContainer: {
    backgroundColor: "white",
    width: "90%",
    height: "90%",
  },

  buttonClose: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});

export default Terms;
