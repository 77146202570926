import { Image } from "expo-image";
import React from "react";
import { View } from "react-native";
import { TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import { BACK_IMAGES, CARD_HEIGHT, CARD_WITDH } from "../types";

const Card = ({
  onClick,
  card,
  index,
  isInactive,
  isFlipped,
  isDisabled,
  overlay,
}) => {
  const handleClick = () => {
    !isFlipped && !isDisabled && onClick(index);
  };
  return (
    <>
      {index === 4 && <View style={styles.empty}></View>}
      <TouchableOpacity
        style={styles.card}
        onPress={handleClick}
        disabled={isFlipped || isInactive}
      >
        {isFlipped || isInactive ? (
          <Image style={styles.image} source={card.image} contentFit="cover" />
        ) : (
          <Image
            contentFit="cover"
            priority={"high"}
            style={styles.image}
            source={BACK_IMAGES[index]}
          />
        )}
        {overlay.includes(card.type) && <View style={styles.overlay} />}
      </TouchableOpacity>
    </>
  );
};
const styles = StyleSheet.create({
  card: {
    width: CARD_WITDH,
    margin: 8,
    justifyContent: "center",
    alignItems: "center",
    height: CARD_HEIGHT,
  },
  empty: { width: Dimensions.get("screen").width },
  image: {
    width: "100%",
    height: "100%",
  },
  overlay: {
    width: CARD_WITDH,
    margin: 8,
    justifyContent: "center",
    alignItems: "center",
    height: CARD_HEIGHT,
    position: "absolute",
    backgroundColor: "#21AA5070",
  },
});

export default Card;
