import React, { useEffect, useState } from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import Game from "./game/Game";
import * as ScreenOrientation from "expo-screen-orientation";
import * as NavigationBar from "expo-navigation-bar";
import { StatusBar } from "expo-status-bar";
import Constants from "expo-constants";

const AndroidSoftwareNavHidden = async () => {
  await NavigationBar.setPositionAsync("relative");
  await NavigationBar.setVisibilityAsync("hidden");
  await NavigationBar.setBehaviorAsync("inset-swipe");
};

const App = () => {
  useEffect(() => {
    async function setScreenOrientation() {
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.LANDSCAPE
      );
    }

    setScreenOrientation();
  }, []);

  useEffect(() => {
    AndroidSoftwareNavHidden();
    console.log("Status-brar", Constants.statusBarHeight);
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <Game />
      <StatusBar hidden />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    overflowX: "hidden",
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default App;
