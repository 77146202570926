import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
  Dimensions,
  Platform,
} from "react-native";
import Card from "./Card";
import CompleteLottie from "./CompleteLottie";
import { SafeAreaView } from "react-native-safe-area-context";
import { IS_LARGE, PADDING_LEFT } from "../types";
const uniqueElementsArray = [
  {
    type: "a",
    image: IS_LARGE
      ? require("../assets/cards/front1l.png")
      : require("../assets/cards/front1.png"),
  },
  {
    type: "b",
    image: IS_LARGE
      ? require("../assets/cards/front2l.png")
      : require("../assets/cards/front2.png"),
  },
  {
    type: "c",
    image: IS_LARGE
      ? require("../assets/cards/front3l.png")
      : require("../assets/cards/front3.png"),
  },
  {
    type: "ad",
    image: IS_LARGE
      ? require("../assets/cards/front4l.png")
      : require("../assets/cards/front4.png"),
  },
];

let bg = IS_LARGE
  ? require("../assets/memory-game-back-1280x800.jpg")
  : require("../assets/memory-game-back-1024x600.jpg");

// Fisher Yates Shuffle
function swap(array, i, j) {
  const temp = array[i];
  array[i] = array[j];
  array[j] = temp;
}

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    swap(array, currentIndex, randomIndex);
  }
  return array;
}

const MemoryGame = ({ setGameStarted }) => {
  const [cards, setCards] = useState([]);
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [overlay, setOverlay] = useState([]);
  const [bestScore, setBestScore] = useState(0);
  const openCardsTimeoutRef = useRef(null);
  const [isGameFinished, setIsGameFinished] = useState(false);

  const disable = () => {
    setShouldDisableAllCards(true);
  };

  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueElementsArray.length) {
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      setTimeout(() => {
        setIsGameFinished(true);
      }, 1000);
    }
  };

  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setOverlay([cards[first].type, cards[second].type]);
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      setTimeout(() => {
        setOverlay([]);
      }, 300);
      return;
    }
    // This is to flip the cards back after 500ms duration
    openCardsTimeoutRef.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };

  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(openCardsTimeoutRef.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    setCards(() =>
      shuffleCards(uniqueElementsArray.concat(uniqueElementsArray))
    );
  }, []);

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);

  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setMoves(0);
    setShouldDisableAllCards(false);
    setCards(() =>
      shuffleCards(uniqueElementsArray.concat(uniqueElementsArray))
    );
    setGameStarted(false);
  };

  return (
    <>
      {!isGameFinished && (
        <ImageBackground
          source={
            Platform.OS === "web"
              ? require("../assets/memory-game-back-grass-1920x1080.jpg")
              : bg
          }
          resizeMode={"cover"}
          style={styles.root}
        >
          <ScrollView>
            <View style={styles.container}>
              {cards.map((card, index) => (
                <Card
                  key={index}
                  card={card}
                  index={index}
                  isDisabled={shouldDisableAllCards}
                  isInactive={checkIsInactive(card)}
                  isFlipped={checkIsFlipped(index)}
                  onClick={handleCardClick}
                  overlay={overlay}
                />
              ))}
            </View>

            {Platform.OS != "web" && (
              <View style={{ flex: 1, alignItems: "center" }}>
                <Text style={styles.totalMoves}>Βρείτε τις ίδιες εικόνες</Text>
              </View>
            )}
          </ScrollView>
        </ImageBackground>
      )}
      {isGameFinished && (
        <>
          <CompleteLottie />
          <View style={{ flex: 1 }}>
            <TouchableOpacity style={styles.btn} onPress={handleRestart}>
              <Text style={{ color: "#fff" }}>Εγγραφή</Text>
            </TouchableOpacity>
          </View>
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: Dimensions.get("screen").width,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    paddingLeft: PADDING_LEFT,
    paddingTop: 30,
    width: "100%",
  },
  btn: {
    backgroundColor: "#21AA50",
    padding: 10,
    borderRadius: 50,
    alignItems: "center",
    marginTop: 40,
  },
  totalMoves: {
    color: "#fff",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    elevation: 8,
    fontWeight: "bold",
    fontSize: 18,
    paddingLeft: PADDING_LEFT,
    paddingTop: 10,
  },
  totalMovesFinal: {
    textAlign: "center",
  },
});

export default MemoryGame;
